import React from 'react'
import { Modal, Button, Form, Table } from 'react-bootstrap'

class ModalCategories extends React.Component {
  static defaultProps = {
    categories: [],
    onCancel: () => {},
    onAddCategory: () => {},
    onDelCategory: () => {},
  }
  state = {
    name: '',
    errors: [],
  }
  handleChange = e => {
    this.setState({ name: e.target.value })
  }

  onAddCategory = () => {
    this.props.onAddCategory(this.state.name)
    this.setState({ name: '' })
  }
  onDelCategory = category_id => () => {
    this.props.onDelCategory(category_id)
  }

  render() {
    let { categories } = this.props
    let { name, errors } = this.state
    return (
      <Modal backdrop="static" size="md" show={true} onHide={this.props.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Categorie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Scrivi il nome della categoria e premi il tasto "Aggiungi" per creare una nuova categoria</p>
          <Form.Group className="mt-3 d-flex col-12">
            <Form.Control
              name="name"
              placeholder="Nome"
              value={name}
              onChange={this.handleChange}
              isInvalid={'name' in errors}
            />
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            <Button onClick={this.onAddCategory} className="ms-2">
              Aggiungi
            </Button>
          </Form.Group>
          <div className="mt-4" style={{ maxHeight: '20rem', overflow: 'auto' }}>
            <div className="mb-1">Lista</div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th style={{ maxWidth: '1rem' }}></th>
                </tr>
              </thead>
              <tbody>
                {categories.map(c => {
                  return (
                    <tr>
                      <td>{c.name}</td>
                      <td style={{ maxWidth: '0.5rem' }}>
                        <Button onClick={this.onDelCategory(c.id)}>
                          <i className="fas fa-trash fa-fw text-start ms-1" />
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCancel} variant="secondary">
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModalCategories
