import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'
import config from '../config'
import { addSuccessNotification, addErrorNotification } from './notifications'

const initialState = {
  devices: {},
  total: 0,
  loading: false,
  show_disabled: true,
  show_form: false,
  current_device: {},
  errors: {},
}

export const getDevices = createAsyncThunk('devices/get_devices', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let state = thunkApi.getState().devices
    let url = `${config.SERVER_API_URL}/devices?`
    if (filter) url = url + `&q=${encodeURIComponent(filter)}`
    if (state.show_disabled) url = url + `&disabled=true`
    if (limit) url = url + `&_end=${encodeURIComponent(limit)}`
    if (offset) url = url + `&_start=${encodeURIComponent(offset)}`
    if (sort_direction) url = url + `&_order=${encodeURIComponent(sort_direction)}`
    if (sort_column) url = url + `&_sort=${encodeURIComponent(sort_column)}`

    let response = await axios({
      url,
      method: 'GET',
    })
    let total = parseInt(response.headers['x-total-count'])
    return { devices: response.data, total }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})
export const createDevice = createAsyncThunk('devices/create_device', async (device, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/devices`,
      method: 'POST',
      data: device,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { device: response.data }
  } catch (err) {
    thunkApi.dispatch(updateErrors({ errors: err.response.data.errors }))
    thunkApi.dispatch(addErrorNotification('Impossibile salvare il device!'))
    return thunkApi.rejectWithValue({})
  }
})

export const updateDevice = createAsyncThunk('devices/update_device', async (device, thunkApi) => {
  try {
    if (!device.id) throw Error('Impossibile salvare!')
    let response = await axios({
      url: `${config.SERVER_API_URL}/devices/${device.id}`,
      method: 'PUT',
      data: device,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { device: response.data }
  } catch (err) {
    thunkApi.dispatch(updateErrors({ errors: err.response.data.errors }))
    thunkApi.dispatch(addErrorNotification('Impossibile salvare il device!'))
    return thunkApi.rejectWithValue({})
  }
})

export const resetPasswordDevice = createAsyncThunk('devices/reset_password', async (device, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/devices/${device.id}/reset_password`,
      method: 'POST',
    })
    thunkApi.dispatch(addSuccessNotification('Reset password inviata per email'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile inviare l'email di reset password"))
    return thunkApi.rejectWithValue()
  }
})

export const toggleDevice = createAsyncThunk('devices/toggle_device', async (device_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/devices/${device_id}/toggle`,
      method: 'GET',
    })
    thunkApi.dispatch(addSuccessNotification('Utente modificato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification('Impossibile modificare il device'))
    return thunkApi.rejectWithValue()
  }
})
export const deleteDevice = createAsyncThunk('devices/delete_device', async (device_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/devices/${device_id}`,
      method: 'DELETE',
    })
    thunkApi.dispatch(addSuccessNotification('Utente eliminato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification('Impossibile eliminare il device'))
    return thunkApi.rejectWithValue()
  }
})

export const deviceinfoSlice = createSlice({
  name: 'devices',
  initialState,

  reducers: {
    setLoading: state => {
      state.loading = true
    },
    toggleDisabledFilter: state => {
      state.show_disabled = !state.show_disabled
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentDevice: state => {
      state.current_device = {}
    },
    updateErrors: (state, action) => {
      state.errors = action.payload.errors
    },
    setCurrentDevice: (state, action) => {
      state.current_device = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getDevices.fulfilled, (state, action) => {
        let { devices, total } = action.payload
        state.devices = devices
        state.total = total
        state.loading = false
      })
      .addCase(getDevices.rejected, (state, action) => {
        state.devices = []
        state.total = 0
        state.loading = false
      })
      .addCase(updateDevice.rejected, () => {
        throw new Error()
      })
      .addCase(createDevice.rejected, () => {
        throw new Error()
      })
  },
})

export const { setLoading, toggleDisabledFilter, resetCurrentDevice, toggleShowForm, updateErrors, setCurrentDevice } =
  deviceinfoSlice.actions
export default deviceinfoSlice.reducer
